import { Link, useParams, useNavigate } from "react-router-dom";
import { AdminPageLayout } from "../../components/admin-page-layout";
import ActionBar from "../../components/action-bar";
import FileUpload from "../../components/file-upload";
import UrlList from "../../components/url-list";
import DateSelector from "../../components/date-selector";
import FileUploader from "../../services/file-uploader";
import { useState } from "react";
import { uid } from "uid";
import { useAuth0 } from "@auth0/auth0-react";
import { EditionModel, RequestType, Url } from "../../models/edition-models";
import StatusModal from "../../components/status-modal";
import { PageLoader } from "../../components/page-loader";

const AdminEditPage = () => {
    const params = useParams();
    const navigate = useNavigate();

    const chunkSize = 100 * 1024;

    const { getAccessTokenSilently } = useAuth0();
    const [videoUploadProgress, setVideoUploadProgress] = useState(0);
    const [videoUploading, setVideoUploading] = useState(false);
    const [videoUploaded, setVideoUploaded] = useState(false);

    const [imageUploadProgress, setImageUploadProgress] = useState(0);
    const [imageUploading, setImageUploading] = useState(false);
    const [, setImageUploaded] = useState(false);

    const [fileUploadProgress, setFileUploadProgress] = useState(0);
    const [fileUploading, setFileUploading] = useState(false);
    const [, setFileUploaded] = useState(false);

    const [isSaving, setIsSaving] = useState(false);

    const [statusMessage, setStatusMessage] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));

    const [urls, setUrls] = useState<Url[]>([]);

    const getAccessTokenHeader = async (): Promise<HeadersInit> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_API_AUDIENCE
            }
        });

        return {
            Authorization: `Bearer ${accessToken}`
        };
    }

    const getFileUploader = async () => {
        const accessTokenHeader = await getAccessTokenHeader();
        return new FileUploader(accessTokenHeader);
    };

    // keep a model of the edition
    const [model, setModel] = useState<EditionModel>({
        title: '',
        date: new Date().toISOString().slice(0, 10),
        requestType: RequestType.Save,
        videoId: '',
        videoExtension: '',
        urls: [],
        files: [],
        images: []
    });

    const handleImageDrop = async (acceptedFiles: File[]) => {
        if (acceptedFiles.length === 0) {
            return;
        }

        const fileId = uid(16);
        setImageUploading(true);
        setImageUploadProgress(1);

        const fileUploader = await getFileUploader();
        const response = await fileUploader.uploadFile(acceptedFiles[0], `${process.env.REACT_APP_API_BASE_URL}/api/upload/?fileId=${fileId}`, chunkSize, (currentChunk, totalChunks) => {
            setImageUploadProgress(currentChunk / totalChunks * 100);
        });

        if (response.status === 'success') {
            model.images.push({
                fileId: fileId,
                fileExtension: acceptedFiles[0].name.split('.').pop() || '',
                title: acceptedFiles[0].name
            });

            setModel(model);
            setImageUploaded(true);
        } else {
            showStatusMessage('Error uploading image - ' + acceptedFiles[0].name);
            setImageUploadProgress(0);
            setImageUploading(false);
            return;
        }

        setTimeout(() => {
            setImageUploading(false);
            setImageUploadProgress(0);
        }, 2000);
    }

    const handleFileDrop = async (acceptedFiles: File[]) => {
        if (acceptedFiles.length === 0) {
            return;
        }

        const fileId = uid(16);
        setFileUploading(true);
        setFileUploadProgress(1);

        const fileUploader = await getFileUploader();
        const response = await fileUploader.uploadFile(acceptedFiles[0], `${process.env.REACT_APP_API_BASE_URL}/api/upload/?fileId=${fileId}`, chunkSize, (currentChunk, totalChunks) => {
            setFileUploadProgress(currentChunk / totalChunks * 100);
        });

        if (response.status === 'success') {
            model.files.push({
                fileId: fileId,
                fileExtension: acceptedFiles[0].name.split('.').pop() || '',
                title: acceptedFiles[0].name
            });

            setFileUploadProgress(100);
            setModel(model);
            setFileUploaded(true);
        } else {
            showStatusMessage('Error uploading file - ' + acceptedFiles[0].name);
            setFileUploadProgress(0);
            setFileUploading(false);
            return;
        }

        setTimeout(() => {
            setFileUploading(false);
            setFileUploadProgress(0);
        }, 2000);
    }

    const handleVideoDrop = async (acceptedFiles: File[]) => {
        if (acceptedFiles.length === 0) {
            return;
        }

        const fileId = uid(16);
        setVideoUploading(true);
        setFileUploadProgress(1);

        const fileUploader = await getFileUploader();
        const response = await fileUploader.uploadFile(acceptedFiles[0], `${process.env.REACT_APP_API_BASE_URL}/api/upload/?fileId=${fileId}`, chunkSize, (currentChunk, totalChunks) => {
            setVideoUploadProgress(currentChunk / totalChunks * 100);
        });

        if (response.status === 'success') {
            model.videoId = fileId;
            model.videoExtension = acceptedFiles[0].name.split('.').pop() || '';
            setModel(model);
            setVideoUploaded(true);
        }

        setTimeout(() => {
            setVideoUploading(false);
            setVideoUploadProgress(0);
        }, 2000);
    }

    const updateEdition = async (requestType: number) => {
        if (!videoUploaded) {
            alert('Please upload a video');
            return;
        }
        if (videoUploading) {
            alert('Please wait for the video to finish uploading');
            return;
        }

        setIsSaving(true);

        model.requestType = requestType;
        model.urls = urls;

        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_API_AUDIENCE
            }
        });
  
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/edition/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json; charset=utf-8'
                },
                method: 'POST',
                body: JSON.stringify(model)
            });

            setIsSaving(false);

            if (!response.ok) {
                showStatusMessage('Error saving edition');
                return;
            }

            showStatusMessage('Edition saved', () => {
                //if (requestType === RequestType.Save) {
                    navigate('/admin', { replace: true });
                //} else {
                //     navigate('/', { replace: true });
                // }
                
            });
        } catch (error) {
            setIsSaving(false);
            showStatusMessage('Error saving edition. Please try again.');
        }
    };

    const handleSave = () => {
        // save to sanity
        updateEdition(RequestType.Save);
    };

    const handlePublish = () => {
        // publish to youtube
        updateEdition(RequestType.Publish);
    };

    const handlePreview = () => {
        // preview
    };

    const showStatusMessage = (message: string, onHide?: () => void) => {
        setStatusMessage(message);
        setTimeout(() => {
            setStatusMessage('');
            if (onHide) {
                onHide();
            }
        }, 2000);
    }

    const handleDateChange = (date: string) => {
        setSelectedDate(date);
        model.date = date;
        setModel(model);
    };

    const handleAddUrl = (url: Url) => {
        setUrls([...urls, url]);
    };

    const handleRemoveUrl = (index: number) => {
        setUrls(urls.filter((_, i) => i !== index));
    };

    return (
        <AdminPageLayout>
            <div className="w-full">
                <h1>TEAM LEWIS - Daily Leaders Brief: {params.id}</h1>

                <div className='flex justify-between items-center container my-5'>
                    <Link className="text-brand-pink" to={'/admin'}>Back</Link>
                    <DateSelector id="publish-date" date={selectedDate} onDateChange={handleDateChange} />
                </div>

                <FileUpload accept={{ 'video/mp4': ['.mp4'] }}
                    isComplete={videoUploaded}
                    maxFiles={1}
                    multiple={false}
                    onDrop={handleVideoDrop}
                    droppingMessage='Drop the video here...'
                    dropMessage='Drag and drop some video here, or click to select video'
                    rejectedMessage="Only MP4 videos are allowed. Please drop one video at a time."
                    uploadingMessage='Uploading video...'
                    uploading={videoUploading}
                    uploadProgress={videoUploadProgress} />

                <FileUpload accept={{ 'image/png': ['.png'], 'image/jpeg': ['.jpe', '.jpeg', '.jpg'], 'image/webp': ['.webp'] }}
                    maxFiles={0}
                    multiple={false}
                    onDrop={handleImageDrop}
                    rejectedMessage="Only PNG, JPEG and WEBP images are allowed. Please drop one image at a time."
                    droppingMessage='Drop the image here...'
                    dropMessage='Drag and drop the fronts images here, or click to select image'
                    uploadingMessage='Uploading image...'
                    uploading={imageUploading}
                    uploadProgress={imageUploadProgress} />

                <FileUpload
                    accept={{ 'application/pdf': ['.pdf'], 'application/msword': ['.doc', '.docx'], 'application/vnd.ms-excel': ['.xls', '.xlsx'], 'application/vnd.ms-powerpoint': ['.ppt', '.pptx'] }}
                    maxFiles={0}
                    multiple={false}
                    onDrop={handleFileDrop}
                    rejectedMessage="Only PDF, Word, Excel and Powerpoint files are allowed. Please drop one file at a time."
                    droppingMessage='Drop the file here...'
                    dropMessage='Drag and drop some files here, or click to select file'
                    uploadingMessage='Uploading file...'
                    uploading={fileUploading}
                    uploadProgress={fileUploadProgress} />

                <UrlList onAddUrl={handleAddUrl} onRemoveUrl={handleRemoveUrl} urls={urls} />

                {statusMessage && <StatusModal message={statusMessage} />}

                {isSaving &&
                    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
                        <PageLoader />
                    </div>
                }

                <ActionBar onPreview={handlePreview} onPublish={handlePublish} onSave={handleSave} />
            </div>
        </AdminPageLayout>
    );
}

export default AdminEditPage;