import React from "react";
import logo from '../images/TEAM-LEWIS-Master-Logo-Black.svg'

interface Props {
  children: JSX.Element;
}

export const PageLayout: React.FC<Props> = ({ children }) => {
  return (
    <main className="flex min-h-screen flex-col items-center p-5 overflow-x-hidden container mx-auto pb-20">
      <div className="relative flex mb-9 place-items-center before:absolute before:h-[300px] before:w-[480px] before:-translate-x-1/2 before:rounded-full before:bg-gradient-radial before:from-white before:to-transparent before:blur-2xl before:content-[''] after:absolute after:-z-20 after:h-[180px] after:w-[240px] after:translate-x-1/3 after:bg-gradient-conic after:from-sky-200 after:via-brand-pink after:blur-2xl after:content-[''] before:dark:bg-gradient-to-br before:dark:from-transparent before:dark:to-brand-pink before:dark:opacity-20 after:dark:from-sky-900 after:dark:via-to-brand-pink after:dark:opacity-40 before:lg:h-[360px] z-[-1]">
        <img
          src={logo}
          alt="TEAM LEWIS Logo"
          className="dark:invert"
          width={100}
          height={40}
        />
      </div>

      <div className="w-full">{children}</div>
    </main>
  );
};
