export type Props = {
    onPublish: () => void;
    onPreview: () => void;
    onSave: () => void;
};

const ActionBar = (props: Props) => {
    return (
        <div className="fixed left-0 w-full bottom-0 bg-black border-t border-t-gray-700">
            <div className="container flex justify-between p-5 mx-auto">
                <button className="text-brand-pink" onClick={props.onPublish}>Publish</button>
                <button disabled className="text-brand-pink opacity-50" onClick={props.onPreview}>Preview</button>
                <button className="text-brand-pink" onClick={props.onSave}>Save</button>
            </div>
        </div>
    );
};

export default ActionBar;