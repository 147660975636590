export type EditionListModel = {
    id: string;
    title: string;
    date: string;
    videoUrl: string;
    slug: string;
    videoThumbnailUrl: string;
    status: EditionStatus;
};

// enum representing 0 for Save, 1 for publish
export enum RequestType {
    Save = 0,
    Publish = 1
}

export enum EditionStatus {
    Draft = 'Draft',
    Published = 'Published'
}

export class EditionModel {
    title: string;
    date: string;
    videoId: string;
    videoExtension: string;
    requestType: RequestType;
    urls: Url[];
    files: File[];
    images: File[];

    constructor(title: string, date: string, videoId: string, videoExtension: string, requestType: RequestType, urls: Url[], files: File[], images: File[]) {
        this.title = title;
        this.date = date;
        this.videoId = videoId;
        this.videoExtension = videoExtension;
        this.requestType = requestType;
        this.urls = urls;
        this.files = files;
        this.images = images;
    }
};

export class EditionViewModel {
    title: string;
    date: string;
    videoUrl: string;
    slug: string;
    urls: Url[];
    files: FileViewModel[];
    images: ImageViewModel[];

    constructor(title: string, date: string, videoUrl: string, slug: string, urls: Url[], files: FileViewModel[], images: ImageViewModel[]) {
        this.title = title;
        this.date = date;
        this.videoUrl = videoUrl;
        this.slug = slug;
        this.urls = urls;
        this.files = files;
        this.images = images;
    }
}

export type FileViewModel = {
    title: string;
    url: string;
}

export type ImageViewModel = {
    caption: string;
    url: string;
}

export type Url = {
    title: string;
    url: string;
}

export type File = {
    fileId: string;
    fileExtension: string;
    title: string;
}