export type FileUploaderResponse = {
    status: 'success' | 'error';
    message: string;
};

class FileUploader {
    headers: HeadersInit;

    constructor(headers: HeadersInit) {
        this.headers = headers;
    }

    async uploadFile(file: File, endpoint: string, chunkSize: number, onChunkUploaded: (currentChunk: number, totalChunks: number) => void): Promise<FileUploaderResponse> {
        let uploadResponse: FileUploaderResponse = {
            status: 'error',
            message: '',
        };

        const totalChunks = Math.ceil(file.size / chunkSize);
        let currentChunk = 0;
        let retryCount = 0;

        while (currentChunk < totalChunks) {
            const start = currentChunk * chunkSize;
            const end = Math.min(start + chunkSize, file.size);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append('file', chunk);

            const chunkEndpoint = `${endpoint}${endpoint.includes('?') ? '&' : '?'}chunk=${(currentChunk+1)}&totalChunks=${totalChunks}&extension=${file.name.split('.').pop()}`;

            try {
                const response = await fetch(chunkEndpoint, {
                    method: 'POST',
                    body: formData,
                    headers: this.headers
                });

                if (!response.ok) {
                    console.error('Error uploading file chunk:', response.statusText);
                    // Handle error here
                    if (retryCount < 3) {
                        retryCount++;
                        continue; // Retry the current chunk
                    } else {
                        return uploadResponse; // Retry limit reached, exit the loop
                    }
                }

                currentChunk++;

                // Call onChunkUploaded method to notify progress
                onChunkUploaded(currentChunk, totalChunks);
            } catch (error) {
                console.error('Error uploading file chunk:', error);
                // Handle error here
                if (retryCount < 3) {
                    retryCount++;
                    continue; // Retry the current chunk
                } else {
                    return uploadResponse; // Retry limit reached, exit the loop
                }
            }
        }

        console.log('File upload complete');
        uploadResponse.status = 'success';
        uploadResponse.message = 'File uploaded successfully';
        return uploadResponse;
    }
}

export default FileUploader;
