import EditionsListView from "../../components/editions-list-view";
import { PageLayout } from "../../components/page-layout";


const ListPage = () => {
    return (
        <PageLayout>
            <div className="flex flex-col items-center">
                <div className='flex justify-between items-center container mb-5'>
                    <h1>TEAM LEWIS - Daily Leaders Brief</h1>
                </div>
                <div className="container">
                    <EditionsListView />
                </div>
            </div>
        </PageLayout>
    );
};

export default ListPage;