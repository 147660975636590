
'use client';

import React from 'react';

export type Props = {
    id: string;
    date: string;
    onDateChange: (date: string) => void;
}

const DateSelector = (props: Props) => {
  //const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setSelectedDate(event.target.value);

    props.onDateChange(event.target.value);
    console.log(event.target.value);
  };

  return (
    <div className='flex gap-1 items-center'>
        <label className='sr-only' htmlFor={props.id}>Date:</label>
        <input id={props.id} value={props.date} onChange={handleDateChange} type="date" className="border-2 border-gray-300 rounded-md p-1 text-black" />
    </div>
  );
};

export default DateSelector;
