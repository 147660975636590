import { Route, Routes } from "react-router-dom";
import './App.css';
import { AuthenticationGuard } from "./components/authentication-guard";
import AdminListPage from "./pages/admin/admin-list-page";
import ListPage from "./pages/editions/list-page";
import AdminEditPage from "./pages/admin/admin-edit-page";
import DetailPage from "./pages/editions/detail-page";

//import React from 'react';

function App() {
  

  return (
    <Routes>
      <Route path="/" element={<ListPage />} />
      <Route path="/:id" element={<DetailPage />} />
      {/* <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />
      <Route path="/public" element={<PublicPage />} />
      <Route
        path="/protected"
        element={<AuthenticationGuard component={ProtectedPage} />}
      /> */}
      <Route
        path="/admin/edit/:id?"
        element={<AuthenticationGuard component={AdminEditPage} />}
      />
      <Route
        path="/admin"
        element={<AuthenticationGuard component={AdminListPage} />}
      />
      {/* <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} /> */}
    </Routes>
  );
}

export default App;
