import React, { useEffect, useState } from 'react';
import { getEditions } from '../services/sanity-client';
import { EditionListModel, EditionStatus } from '../models/edition-models';
import { Link } from 'react-router-dom';

export type Props = {
    isPreview?: boolean;
};

const EditionsList: React.FC = (props: Props) => {
    const [items, setItems] = useState<EditionListModel[]>([]);

    useEffect(() => {
        // Fetch items from API and update state
        const fetchItems = async () => {
            try {
                const items = await getEditions(true);

                console.log('items', items);

                setItems(items);
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };

        fetchItems();
    }, []);

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
            {items.map((item) => (
                <div key={item.id} className="text-center flex flex-col">
                    <img src={item.videoThumbnailUrl} alt="" className="w-full object-cover mb-2 grow" />
                    <p>{item.date}</p>
                    <p>{item.status}</p>
                    <p className='flex gap-2 justify-center mt-2'>
                        <span>Edit</span><span className='text-brand-pink-'>Delete</span>
                        {item.status === EditionStatus.Published && <Link to={'/' + item.id} className='text-brand-pink'>View</Link>}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default EditionsList;
