import { Link } from "react-router-dom";
import { AdminPageLayout } from "../../components/admin-page-layout";
import EditionsList from "../../components/editions-list";

const AdminListPage = () => {
    return (
        <AdminPageLayout>
            <div className="flex flex-col items-center">
                <div className='flex justify-between items-center container mb-5'>
                    <h1>TEAM LEWIS - Daily Leaders Brief</h1>
                    <Link className="text-brand-pink" to={'/admin/edit'}>Create New</Link>
                </div>
                <div className="container">
                    <EditionsList />
                </div>
            </div>
        </AdminPageLayout>
    );
};

export default AdminListPage;