import { createClient } from '@sanity/client'
import { EditionListModel, EditionStatus, EditionViewModel } from '../models/edition-models';

export const client = createClient({
    projectId: '0e8w9b1b',
    dataset: 'production',
    perspective: 'published',
    useCdn: true, // set to `false` to bypass the edge cache
    apiVersion: '2023-05-03', // use current date (YYYY-MM-DD) to target the latest API version
    // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
});

export const draftClient = createClient({
    projectId: '0e8w9b1b',
    dataset: 'production',
    perspective: 'previewDrafts',
    useCdn: false, // set to `false` to bypass the edge cache
    apiVersion: '2023-05-03', // use current date (YYYY-MM-DD) to target the latest API version
    token: process.env.REACT_APP_SANITY_VIEWER_API_TOKEN
    // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
});

function getClient(preview: boolean = false) {
    return preview ? draftClient : client
}

export async function getEditions(preview: boolean = false) : Promise<EditionListModel[]> {
    const response = await getClient(preview).fetch(
        `*[_type == "edition"] | order(date desc) {
        _id,
        title,
        date,
        videoUrl,
        videoThumbnailUrl,
        slug,
        _originalId
      }`);

    const results = response.map((item: any) => {
        let result: EditionListModel = {
            id: item._id,
            title: item.title,
            date: item.date,
            videoUrl: item.videoUrl,
            slug: item.slug.current,
            videoThumbnailUrl: item.videoThumbnailUrl,
            status: item._originalId?.startsWith('drafts.') ? EditionStatus.Draft : EditionStatus.Published
        };

        if (!item.videoThumbnailUrl && item.videoUrl) {
            // extract thumbnail url from video url
            const videoId = item.videoUrl.split('v=')[1];
            result.videoThumbnailUrl = `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg`;
        }

        return result;
    });
    
    return results;
}

export async function getEditionBySlug(slug: string, preview: boolean = false): Promise<EditionViewModel | null> {
    const results = await getClient(preview).fetch(
        `*[_type == "edition" && slug.current == $slug] {
        _id,
        title,
        date,
        videoUrl,
        slug,
        "urls": urls[] {
            title,
            url
         },
         "images": images[]{
           caption,
           "url": image.asset->url,
         },
         "files": files[]{
           title,
           "url": file.asset->url,
         }
      }`, { slug });

    if (!results || results.length === 0) {
        return null;
    }

    return mapResult(results[0]);
}

export async function getEditionById(id: string, preview: boolean = false): Promise<EditionViewModel | null> {
    const results = await getClient(preview).fetch(
        `*[_type == "edition" && _id == $id] {
        _id,
        title,
        date,
        videoUrl,
        slug,
        "urls": urls[] {
            title,
            url
         },
         "images": images[]{
           caption,
           "url": image.asset->url,
         },
         "files": files[]{
           title,
           "url": file.asset->url,
         }
      }`, { id });

    if (!results || results.length === 0) {
        return null;
    }

    return mapResult(results[0]);
}

function mapResult(result: any): EditionViewModel {
    return {
        title: result.title,
        date: result.date,
        videoUrl: result.videoUrl,
        slug: result.slug.current,
        urls: result.urls,
        files: result.files,
        images: result.images
    };
}