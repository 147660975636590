import { PageLayout } from "../../components/page-layout";
import { Loader } from "../../components/loader";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getEditionById } from "../../services/sanity-client";
import { EditionViewModel } from "../../models/edition-models";
import ReactPlayer from 'react-player'

const DetailPage = () => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const id = params.id;
    const [item, setItem] = useState<EditionViewModel>();

    useEffect(() => {
        // Fetch items from API and update state
        const fetchItem = async () => {
            try {
                const response = await getEditionById(id!,  false);

                console.log('items', response);

                if (!response) {
                    return;
                }

                setItem(response!);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };

        fetchItem();
    }, [id]);

    return (
        <PageLayout>
            <div className="w-full">
                
                <div className='flex justify-between items-center container mb-10'>
                    <h1>TEAM LEWIS - Daily Leaders Brief {item && <span>- {item.date}</span>}</h1>
                    <Link className="text-brand-pink" to={'..'}>Back</Link>
                </div>

                {loading && <Loader />}

                {item && (
                    <section>
                        <div className="w-full" style={{ position: 'relative', paddingTop: '56.25%' }}>
                            <ReactPlayer
                                style={{ position: 'absolute', top: 0, left: 0 }}
                                width='100%'
                                height='100%'
                                url={item.videoUrl}
                                controls={true}
                            />
                        </div>

                        {item.images && item.images.length > 0 &&
                        <div className="py-5 mt-5">
                            <h5 className="mb-2">Images:</h5>
                            <div>
                                {item.images.map((image, index) => (
                                    <img key={index} src={image.url} alt={image.caption} className="max-w-full object-cover mb-2" />
                                ))}
                            </div>
                        </div>
                        }

                        {item.files && item.files.length > 0 &&
                        <div className="py-5">
                            <h5 className="mb-2">Files:</h5>
                            <div>
                                {item.files.map((file, index) => (
                                    <div key={index} className="mb-2">
                                        <a className="text-brand-pink" href={file.url} target="_blank" rel="noreferrer">{file.title}</a>
                                    </div>
                                ))}
                            </div>
                        </div>
                        }

                        {item.urls && item.urls.length > 0 &&
                        <div className="py-5">
                            <h5 className="mb-2">Links:</h5>
                            <div>
                                {item.urls.map((link, index) => (
                                    <div key={index} className="mb-2">
                                        <a className="text-brand-pink" href={link.url} target="_blank" rel="noreferrer">{link.title}</a>
                                    </div>
                                ))}
                            </div>
                        </div>
                        }
                    </section>
                )}
            </div>
        </PageLayout>
    );
}

export default DetailPage;