
export type Props = {
    progress: number;
};

const ProgressBar = ({ progress }: Props) => {
  return (
    <div className="w-full h-full absolute bg-gray-200 opacity-50">
      <div
        className="h-full bg-blue-500 transition-all duration-500 ease-in-out"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
