'use client';

import React, { useCallback } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
//import Image from 'next/image';
import ProgressBar from './progress-bar';

// create props that accepts an accept param that is an array of strings
export type Props = {
    accept: Accept;
    onDrop?: (acceptedFiles: File[]) => void;
    dropMessage: string;
    droppingMessage: string;
    uploadingMessage: string;
    rejectedMessage: string;
    multiple: boolean;
    maxFiles: number;
    uploading?: boolean;
    uploadProgress: number;
    isComplete?: boolean;
};

const FileUpload = (props: Props) => {
  const [isRejected, setIsRejected] = React.useState(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (props.onDrop) {
      props.onDrop(acceptedFiles);
    }
  }, [props]);

  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    console.log('rejected', fileRejections);
    setIsRejected(true);

    setTimeout(() => {
      setIsRejected(false);
    }, 3000);
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, onDropRejected, multiple: props.multiple, maxFiles: props.maxFiles, accept: props.accept});

  return (
    <div className={`relative p-5 mb-4 flex items-center rounded-sm justify-center border border-gray-700 w-full min-h-[7rem] ${isRejected  ? 'border-red-400' : ''} ${isDragActive || props.isComplete ? 'border-green-400' : ''}`} {...getRootProps()}>
      {/* {isDragActive &&
        <img src="/images/daily-planet-boss.jpg" alt="Daily planet boss" className='object-cover -z-10 opacity-70 absolute w-full h-full' />
      } */}



      {props.uploading && <ProgressBar progress={props.uploadProgress} />}

      <input {...getInputProps()} />
      {
        isRejected ?
          <p>{props.rejectedMessage}</p> :
        props.isComplete ?
          <p>File uploaded!</p> :
        isDragActive ?
          <p>{props.droppingMessage}</p> :
          <p>{props.uploading ? props.uploadingMessage : props.dropMessage}</p>
      }
    </div>
  )
}

export default FileUpload;