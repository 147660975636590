'use client';

import { useState } from 'react';
import { Url } from '../models/edition-models';

export type Props = {
    urls: Url[];
    onAddUrl: (url: Url) => void;
    onRemoveUrl: (index: number) => void;
}

const UrlList = (props: Props) => {
    const [newUrl, setNewUrl] = useState<Url>({ title: '', url: '' });

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewUrl({ ...newUrl, title: event.target.value });
    };

    const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewUrl({ ...newUrl, url: event.target.value });
    };

    const handleAddUrl = () => {
        //setUrls([...urls, newUrl]);
        props.onAddUrl(newUrl);
        setNewUrl({ title: '', url: '' });

        //setUrls(urls.filter((_, i) => i !== index))
    };

    return (
        <div className='flex container flex-col mt-10'>
            <h5>Links:</h5>
            <ul>
                {props.urls.map((url, index) => (
                    <li key={index}>
                        <a href={url.url}>{url.title}</a>
                        <button className='ml-5 text-brand-pink' onClick={() => props.onRemoveUrl(index)}>Remove</button>
                    </li>
                ))}
            </ul>
            <div className='mt-2 flex flex-col md:flex-row gap-3'>
                <input className='dark:text-white p-1 rounded-sm border border-gray-700 bg-transparent' type="text" placeholder="Title" value={newUrl.title} onChange={handleTitleChange} />
                <input className='dark:text-white p-1 rounded-sm border border-gray-700 bg-transparent' type="url" placeholder="URL" value={newUrl.url} onChange={handleUrlChange} />
                <button className='text-brand-pink' onClick={handleAddUrl}>Add URL</button>
            </div>
        </div>
    );
}

export default UrlList;