import React from 'react';

const StatusModal: React.FC<{ message: string }> = ({ message }) => {
    return (
        <div
            className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-75 p-4 rounded-md w-1/2 h-1/2 z-50 flex items-center justify-center border-2 border-slate-500"
        >
            <div className="text-white">{message}</div>
        </div>
    );
};

export default StatusModal;
